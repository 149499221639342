import IMG_1714 from "./IMG_1714.JPG";
import IMG_1714_th from "./thumbs/IMG_1714_th.JPG";
import IMG_1718 from "./IMG_1718.JPG";
import IMG_1718_th from "./thumbs/IMG_1718_th.JPG";
import IMG_1725 from "./IMG_1725.JPG";
import IMG_1725_th from "./thumbs/IMG_1725_th.JPG";
import IMG_1745 from "./IMG_1745.JPG";
import IMG_1745_th from "./thumbs/IMG_1745_th.JPG";
import IMG_1787 from "./IMG_1787.JPG";
import IMG_1787_th from "./thumbs/IMG_1787_th.JPG";
import IMG_1802 from "./IMG_1802.JPG";
import IMG_1802_th from "./thumbs/IMG_1802_th.JPG";
import IMG_1826 from "./IMG_1826.JPG";
import IMG_1826_th from "./thumbs/IMG_1826_th.JPG";
import IMG_1837 from "./IMG_1837.JPG";
import IMG_1837_th from "./thumbs/IMG_1837_th.JPG";
import IMG_1845 from "./IMG_1845.JPG";
import IMG_1845_th from "./thumbs/IMG_1845_th.JPG";
import IMG_1859 from "./IMG_1859.JPG";
import IMG_1859_th from "./thumbs/IMG_1859_th.JPG";
import IMG_1873 from "./IMG_1873.JPG";
import IMG_1873_th from "./thumbs/IMG_1873_th.JPG";
import IMG_1886 from "./IMG_1886.JPG";
import IMG_1886_th from "./thumbs/IMG_1886_th.JPG";
import IMG_1921 from "./IMG_1921.JPG";
import IMG_1921_th from "./thumbs/IMG_1921_th.JPG";
import IMG_1959 from "./IMG_1959.JPG";
import IMG_1959_th from "./thumbs/IMG_1959_th.JPG";
import IMG_1975 from "./IMG_1975.JPG";
import IMG_1975_th from "./thumbs/IMG_1975_th.JPG";
import IMG_2015 from "./IMG_2015.JPG";
import IMG_2015_th from "./thumbs/IMG_2015_th.JPG";
import IMG_2016 from "./IMG_2016.JPG";
import IMG_2016_th from "./thumbs/IMG_2016_th.JPG";
import IMG_2030 from "./IMG_2030.JPG";
import IMG_2030_th from "./thumbs/IMG_2030_th.JPG";
import IMG_2057 from "./IMG_2057.JPG";
import IMG_2057_th from "./thumbs/IMG_2057_th.JPG";
import IMG_2070 from "./IMG_2070.JPG";
import IMG_2070_th from "./thumbs/IMG_2070_th.JPG";
import IMG_2076 from "./IMG_2076.JPG";
import IMG_2076_th from "./thumbs/IMG_2076_th.JPG";
import IMG_2101 from "./IMG_2101.JPG";
import IMG_2101_th from "./thumbs/IMG_2101_th.JPG";
import IMG_2110 from "./IMG_2110.JPG";
import IMG_2110_th from "./thumbs/IMG_2110_th.JPG";
import IMG_2157 from "./IMG_2157.JPG";
import IMG_2157_th from "./thumbs/IMG_2157_th.JPG";
import IMG_2172 from "./IMG_2172.JPG";
import IMG_2172_th from "./thumbs/IMG_2172_th.JPG";
import IMG_2176 from "./IMG_2176.JPG";
import IMG_2176_th from "./thumbs/IMG_2176_th.JPG";
import IMG_2225 from "./IMG_2225.JPG";
import IMG_2225_th from "./thumbs/IMG_2225_th.JPG";
import IMG_2247 from "./IMG_2247.JPG";
import IMG_2247_th from "./thumbs/IMG_2247_th.JPG";
import IMG_2266 from "./IMG_2266.JPG";
import IMG_2266_th from "./thumbs/IMG_2266_th.JPG";
import IMG_2332 from "./IMG_2332.JPG";
import IMG_2332_th from "./thumbs/IMG_2332_th.JPG";
import IMG_2378 from "./IMG_2378.JPG";
import IMG_2378_th from "./thumbs/IMG_2378_th.JPG";
import IMG_2379 from "./IMG_2379.JPG";
import IMG_2379_th from "./thumbs/IMG_2379_th.JPG";
import IMG_2417 from "./IMG_2417.JPG";
import IMG_2417_th from "./thumbs/IMG_2417_th.JPG";
import IMG_2475 from "./IMG_2475.JPG";
import IMG_2475_th from "./thumbs/IMG_2475_th.JPG";
import IMG_2510 from "./IMG_2510.JPG";
import IMG_2510_th from "./thumbs/IMG_2510_th.JPG";
import IMG_2531 from "./IMG_2531.JPG";
import IMG_2531_th from "./thumbs/IMG_2531_th.JPG";
import IMG_2590 from "./IMG_2590.JPG";
import IMG_2590_th from "./thumbs/IMG_2590_th.JPG";
import IMG_2600 from "./IMG_2600.JPG";
import IMG_2600_th from "./thumbs/IMG_2600_th.JPG";
import IMG_2644 from "./IMG_2644.JPG";
import IMG_2644_th from "./thumbs/IMG_2644_th.JPG";
import IMG_2659 from "./IMG_2659.JPG";
import IMG_2659_th from "./thumbs/IMG_2659_th.JPG";
import IMG_2696 from "./IMG_2696.JPG";
import IMG_2696_th from "./thumbs/IMG_2696_th.JPG";
import IMG_2715 from "./IMG_2715.JPG";
import IMG_2715_th from "./thumbs/IMG_2715_th.JPG";
import IMG_2724 from "./IMG_2724.JPG";
import IMG_2724_th from "./thumbs/IMG_2724_th.JPG";
import IMG_2746 from "./IMG_2746.JPG";
import IMG_2746_th from "./thumbs/IMG_2746_th.JPG";
import IMG_2751 from "./IMG_2751.JPG";
import IMG_2751_th from "./thumbs/IMG_2751_th.JPG";
import IMG_2766 from "./IMG_2766.JPG";
import IMG_2766_th from "./thumbs/IMG_2766_th.JPG";
import IMG_2784 from "./IMG_2784.JPG";
import IMG_2784_th from "./thumbs/IMG_2784_th.JPG";
import IMG_2796 from "./IMG_2796.JPG";
import IMG_2796_th from "./thumbs/IMG_2796_th.JPG";
import IMG_2819 from "./IMG_2819.JPG";
import IMG_2819_th from "./thumbs/IMG_2819_th.JPG";
import IMG_2831 from "./IMG_2831.JPG";
import IMG_2831_th from "./thumbs/IMG_2831_th.JPG";
import IMG_2836 from "./IMG_2836.JPG";
import IMG_2836_th from "./thumbs/IMG_2836_th.JPG";

import IMG_2840 from "./IMG_2840.JPG";
import IMG_2840_th from "./thumbs/IMG_2840_th.JPG";
import IMG_2872 from "./IMG_2872.JPG";
import IMG_2872_th from "./thumbs/IMG_2872_th.JPG";
import IMG_2880 from "./IMG_2880.JPG";
import IMG_2880_th from "./thumbs/IMG_2880_th.JPG";
import IMG_2909 from "./IMG_2909.JPG";
import IMG_2909_th from "./thumbs/IMG_2909_th.JPG";


const images = [	
	
    {full: IMG_1714, thumb: IMG_1714_th, caption: "Two days after we moved into our house!"},
	{full: IMG_1718, thumb: IMG_1718_th, caption: "One of our earliest visitors."},
	{full: IMG_1725, thumb: IMG_1725_th, caption: "First sighting of our resident kingfisher."},
	{full: IMG_1745, thumb: IMG_1745_th, caption: "A really well-disguised praying mantis, on the verandah wall. Creepy, but cute."},
	{full: IMG_1787, thumb: IMG_1787_th, caption: "Big gecko on the kitchen wall - not the biggest one we have seen!"},
	{full: IMG_1802, thumb: IMG_1802_th, caption: "Find the four hidden birds!"},
	{full: IMG_1826, thumb: IMG_1826_th, caption: "Two speckled mousebirds at the top of a tree overlooking the whole area."},
	{full: IMG_1837, thumb: IMG_1837_th, caption: "Here I sits, enjoying the sun, and munching on something."},
	{full: IMG_1845, thumb: IMG_1845_th, caption: "Burchell's coucal, looking for snails in the hedge."},
	{full: IMG_1859, thumb: IMG_1859_th, caption: "Water dikkop (also known as thick-knee)."},
	{full: IMG_1873, thumb: IMG_1873_th, caption: "Green water snake - pretty little thing, but thank goodness for zoom lenses!"},
	{full: IMG_1886, thumb: IMG_1886_th, caption: "This might be a female Greater Double-collared Sunbird."},
	{full: IMG_1921, thumb: IMG_1921_th, caption: "Tap tap tap - and there is a woodpecker, hanging upside down, making holes in a tree."},
	{full: IMG_1959, thumb: IMG_1959_th, caption: "Oh, the poor gecko - but a kingfisher must eat."},
	{full: IMG_1975, thumb: IMG_1975_th, caption: "Black-collared barbet - don't see him often, but managed to snap him while he was here."},
	{full: IMG_2015, thumb: IMG_2015_th, caption: "Another praying mantis, trying, but not succeeding, to look inconspicuous."},
	{full: IMG_2016, thumb: IMG_2016_th, caption: "A weird little spider."},
	{full: IMG_2030, thumb: IMG_2030_th, caption: "Earliest photo of a baby monkey - dreadful photo, but the baby can be clearly seen."},
	{full: IMG_2057, thumb: IMG_2057_th, caption: "Both these females have babies, only one visible. Cute little face!"},
	{full: IMG_2070, thumb: IMG_2070_th, caption: "We had a swarm of bees arrive in our back garden. Had to get a local bee-keeper to remove them."},
	{full: IMG_2076, thumb: IMG_2076_th, caption: "Little one is growing, but still clinging to mama."},
	{full: IMG_2101, thumb: IMG_2101_th, caption: "The male Greater Double-collared Sunbird.  Beautiful little bird!"},
	{full: IMG_2110, thumb: IMG_2110_th, caption: "Grooming in the sun, while the little one discovers the world."},
	{full: IMG_2157, thumb: IMG_2157_th, caption: "It's such a great big world, sometimes I get a little nervous."},
	{full: IMG_2172, thumb: IMG_2172_th, caption: "Another one of our beautiful geckos."},
	{full: IMG_2176, thumb: IMG_2176_th, caption: "Kingfisher enjoying the sun, watching me carefully."},
	{full: IMG_2225, thumb: IMG_2225_th, caption: "One of the neighbourhood cats, sitting in a tree, looking longingly at two doves."},
	{full: IMG_2247, thumb: IMG_2247_th, caption: "A pair of Wood Hoopoes, and I believe that the bird on the upper right is one of the Drongo family."},
	{full: IMG_2266, thumb: IMG_2266_th, caption: "The monkeys are a never-ending source of amusement!  Sitting on the fence, leg hanging down, just chilling."},
	{full: IMG_2332, thumb: IMG_2332_th, caption: "An Olive Thrush, just visiting."},
	{full: IMG_2378, thumb: IMG_2378_th, caption: "Kingfisher's home - a hole in the bank of earth."},
	{full: IMG_2379, thumb: IMG_2379_th, caption: "And there he is!  Kingfisher leaving home.  The young one appeared shortly after this time."},
	{full: IMG_2417, thumb: IMG_2417_th, caption: "Monkeys on the telephone wire.  Sometimes they are so funny."},
	{full: IMG_2475, thumb: IMG_2475_th, caption: "Kingfisher with one of his catches.  Not sure what this is.  We have legless skinks around, this might be one of them."},
	{full: IMG_2510, thumb: IMG_2510_th, caption: "One of the baby monkeys.  So cute, so mischievous!"},
	{full: IMG_2531, thumb: IMG_2531_th, caption: "A little Blue Duiker, seen through the fence."},
	{full: IMG_2590, thumb: IMG_2590_th, caption: "Mother and baby.  Vervet monkeys are very affectionate mothers."},
	{full: IMG_2600, thumb: IMG_2600_th, caption: "This is only half of the troop!  They love to play on the hill behind our house."},
	{full: IMG_2644, thumb: IMG_2644_th, caption: "Our beautiful brown-hooded kingfisher again - I just can't resist taking photos of him."},
	{full: IMG_2659, thumb: IMG_2659_th, caption: "This vile, disgusting looking thing is a Dog Stinkhorn Mushroom.  They pop up after lengthy rains."},
	{full: IMG_2696, thumb: IMG_2696_th, caption: "As noted before, we have MANY geckos, lizards and skinks.  This is a particularly cute little guy."},
	{full: IMG_2715, thumb: IMG_2715_th, caption: "They turn up everywhere, and anywhere.  They even climb the curtains."},
	{full: IMG_2724, thumb: IMG_2724_th, caption: "This little gecko was thirsty, so he came down to slurp up some soap bubbles from the kitchen sink!"}, 
	{full: IMG_2746, thumb: IMG_2746_th, caption: "Another specimen of the vile Dog Stinkhorn Mushroom."},
	{full: IMG_2751, thumb: IMG_2751_th, caption: "I am certain that this is a Tiger Moth, but I cannot find any examples with this colouring.  Very pretty."},
	{full: IMG_2766, thumb: IMG_2766_th, caption: "Another little gecko, thinking he can hide in the gap between bricks.  Cutest little face."},
	{full: IMG_2784, thumb: IMG_2784_th, caption: "A Pin Tailed Whydah.  There seem to be quite a few of them living around here."}, 
	{full: IMG_2796, thumb: IMG_2796_th, caption: "Have been unable to identify this bird.  But he has quite a flamboyant hunting technique!"}, 
	{full: IMG_2819, thumb: IMG_2819_th, caption: "A monkey, at the top of the bush.  Just sitting there admiring the beautiful view."},
	{full: IMG_2831, thumb: IMG_2831_th, caption: "Yet another example of the disgusting Dog Stinkhorn Mushroom."},
	{full: IMG_2836, thumb: IMG_2836_th, caption: "The monkeys alerted me to this cluster of horrible flies on the ground."}, 
	{full: IMG_2840, thumb: IMG_2840_th, caption: "Right up at the top of the hill, almost in the wild bush - a Flame Lily, Gloriosa Superba."},
	{full: IMG_2872, thumb: IMG_2872_th, caption: "After the rains, this little grove of mushrooms appeared, like a tiny forest."},
	{full: IMG_2880, thumb: IMG_2880_th, caption: "A Garden Orb Weaver spider, strung between two plants in the garden."},
	{full: IMG_2909, thumb: IMG_2909_th, caption: "A rather large praying mantis, lurking on the railing up to the deck."},

]

export default images;

// tiger moth - orange, black, white
// pin tailed whydah
