
import styles from '../styles/home.module.css';
import homePic from '../images/home.jpg';

const Home = () => {

    const linkVervetMonkey = "https://www.awf.org/wildlife-conservation/vervet-monkey";
    const linkBrownHoodedKingfisher = "http://biodiversityexplorer.info/birds/dacelonidae/halcyon_albiventris.htm";
    const linkBlueDuiker = "https://www.sanbi.org/animal-of-the-week/blue-duiker/";

    const target = "_blank";

    return (
        <div>
            <div className={styles.homePage} >

                <h1>Home</h1>

                <img src={homePic} className={styles.homeImg} alt="Beautiful view" title="The view from the hill behind our house" />

                <p>Home is a little seaside village on the southern coast of Africa.</p>

                <p>There is a steep hill behind our house, from which we have a lovely view of the sea, over the roofs of the houses.  From our front garden, we can step out of the gate, turn to the right, and look down the road straight to the sea.</p>

                <p>It is such a short distance down to the sea, that we could take a cup of coffee and walk down in the early morning, and drink it on the rocks before it even had time to get cold!</p>
                
                <p>Falling asleep with the sound of the sea in your ears is wonderful.  On wild nights, when the wind is blowing and the tide is high, and those waves come crashing in against the rocks, you can snuggle down in bed and relax, listening to the elements raging around you. What a wonderful life!</p>

                <p>Living close to a "green belt", one sees - and HEARS - dozens of species of birds.</p>
                <p>We also have a large troop of resident <a href={linkVervetMonkey} target={target}>vervet monkeys</a>.  The babies are so cute!  And yes, the monkeys are pesky, and raid your kitchen, but one just takes precautions to keep them out.  There is room in the world for me and the monkeys!</p>

                <p>We have a pair of <a href={linkBrownHoodedKingfisher} target={target}>brown-hooded kingfishers</a> nesting in our back garden.  They make their nests in holes in a bank, and fortunately we have a suitable bank of earth for them.  They have dug a beautiful hole, and some months ago they had a chick.  He has gone off to find his own territory now, but it was lovely to have him brought up in our garden.
                </p>

                <p>There are buck (little <a href={linkBlueDuiker} target={target}>blue duiker</a>, amongst others) in the bush behind our house, and we see them from time to time through the fence.</p>

                <p>Around October, we see whales passing by on their way south.  Last year we saw a mother and a new-born calf quite close to the shore.  What a very special experience it was, sitting on the rocks with friends and watching them move slowly and lazily past.</p>

                <p>But the creatures that we have most abundantly are geckos!  All sizes and shades, from dark, almost black ones, to the pale pink ones, through whose skins you can almost see what they had for breakfast.  From tiny little ones a few centimetres long, to great big "Godzilla" sized ones, who lurk around the kitchen walls at night and catch anything that moves.  A wonderful natural pest control. </p>

                <p>Many people consider geckos to be pests, and complain bitterly about the mess they make.  Yes, all those bugs that they munched during the night have to be pooped out at some stage!  Personally, I would rather clean up the gecko poop, than have bugs invading my kitchen.</p>

                <p>And believe me, when you live in mosquito territory, you really NEED geckos!</p>
            </div>
        </div>
    );
    
};

export default Home;