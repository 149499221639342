import { Outlet, Link } from 'react-router-dom';
import styles from '../styles/layout.module.css';

const Layout = () => {
    return (
        <>
        <div className={styles.layoutStyle}>
            <h1 className={styles.h1}>Coffee On The Rocks</h1>
            <nav>
                <Link to="/" className={styles.linkStyle}>Home</Link>
                <Link to="/blogs" className={styles.linkStyle}>Blogs</Link>
                <Link to="/gallery" className={styles.linkStyle}>Gallery</Link>
                {/* 
                <Link to="/projects" className={styles.linkStyle}>Projects</Link>
                */}
                <Link to="/"></Link>
            </nav>

            <Outlet />
        </div>
        </>
    );
};

export default Layout;