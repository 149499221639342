import React from 'react';

import styles from '../styles/gallery.module.css';

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            slideIndex: 0
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.gotoSlide = this.gotoSlide.bind(this);
    }

    openModal(index) {
        this.setState({open: true, slideIndex: index})
    }

    closeModal() {
        this.setState({open: false})
    }

    nextSlide() {
        let nextSlide = this.state.slideIndex + 1;
        if (nextSlide >= this.props.images.length) nextSlide = 0;
        this.setState({slideIndex: nextSlide});
    }

    prevSlide() {
        let nextSlide = this.state.slideIndex - 1;
        if (nextSlide < 0) nextSlide = this.props.images.length - 1;
        this.setState({slideIndex: nextSlide});
    }

    // Thumbnail image controls
    gotoSlide(index) {
        this.setState({slideIndex: index});
    }

    render() {
        return (
            <>                
                <GalleryModal images={this.props.images} 
                    open={this.state.open} 
                    slideIndex={this.state.slideIndex} 
                    closeAction={this.closeModal} 
                    nextAction={this.nextSlide} 
                    prevAction={this.prevSlide} 
                    gotoAction={this.gotoSlide} />

                <GalleryThumbs images={this.props.images} 
                    openAction={this.openModal} />
            </>
        );
    }
}

class GalleryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.thumbs = props.images.map((img, idx) => (
            <div key={idx}>
                <img className={styles.thumbsFlowImg} src={img.thumb} 
                    onClick={() => props.gotoAction(idx)} alt={img.caption} />
            </div>
        ));
    }

    render() {
        if (!this.props.open) {
            return <></>
        } else {
            let image = this.props.images[this.props.slideIndex]
            return(
                <div className={styles.modal}> 

                    <span className={styles.close} onClick={this.props.closeAction}>&times;</span>

                    {/* Moved numbers outside of full image modal so that it does not get 'lost' in the image content. */}
                    <div className={styles.numbertext}>
                        {this.props.slideIndex + 1} / {this.props.images.length}    
                    </div>

                    <div className={styles.modalContent}>
                        <div className={styles.mySlides}>
                            <img src={image.full} alt={image.caption} />
                        </div>
                    

                        <div className={styles.prev} onClick={this.props.prevAction}>&#10094;</div>
					    <div className={styles.next} onClick={this.props.nextAction}>&#10095;</div>

                        <div className={styles.captionContainer}>
                            <p>{this.props.images[this.props.slideIndex].caption}</p>
                        </div>
                    </div>

                    {/* Moved thumbs carousel outside of full image modal so that it can fill the width of the screen. */}
                    <div className={styles.thumbsFlow}>
                        {this.thumbs}
                    </div>

                </div>
            )
        }
    }
}

class GalleryThumbs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.thumbs = props.images.map((img, idx) => (
            <div key={idx}>
                <img src={img.thumb} 
                    onClick={() => props.openAction(idx)} 
                    className={styles.galleryThumb} 
                    alt={img.caption} />
            </div>
        ));
    }

    render() {
        return (
            <>
                <div className={styles.galleryPage}>
                    {this.thumbs}
                </div>
            </>
            
        );
    };
}

export default Gallery;